import PropTypes from "prop-types"
import React, { Fragment } from "react"

const Footer = () => {
  return (
    <Fragment>
      <section id="bottom" className="wet-asphalt">
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-centered">
              <a
                href="https://www.paypal.me/AJVDC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://www.paypalobjects.com/webstatic/en_US/i/btn/png/btn_donate_cc_147x47.png"
                  alt="Donate"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <h4>About Us</h4>
              <p>
                AJVDC envision peace and development restoration in Eastern of
                Congo, where it is better to live for children and women, for
                this and future generation.
              </p>
            </div>

            <div className="col-md-4 col-sm-6">
              <h4>Address</h4>
              <address>
                <strong>Avenue Kituku N° 04, Quartier Kyshero</strong>
                <br />
                Commune de Goma, Ville de Goma
                <br />
                Republiaue Démocratique du Congo
                <br />
              </address>
              <h4>Email</h4>
              <address>
                <strong>
                  {" "}
                  <a href="mailto:ajvdccongovision@yahoo.fr">
                    ajvdccongovision@yahoo.fr
                  </a>
                </strong>
              </address>
            </div>

            <div className="col-md-4 col-sm-6">
              <h4>Phone</h4>
              <address>
                <abbr title="Phone">P:</abbr> +243 815662837
              </address>
              <h4>Skype</h4>
              <address>
                <abbr title="Phone">P:</abbr> simweragi
              </address>
            </div>
          </div>
        </div>
      </section>

      <footer id="footer" className="midnight-blue">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a> developed by{" "}
              <a
                target="_blank"
                href="http://www.quincewebdesign.com/"
                title="quincewebdesign.com"
                rel="noopener noreferrer"
              >
                QuinceWebDesign
              </a>
              .
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
