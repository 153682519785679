import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "gatsby"

const Navigation = props => {
  const setActive = target => {
    if (target === props.pageName) {
      return "active"
    }
  }

  const [programDropDownOpen, setProgramDropDownOpen] = useState(false)
  const [projectsDropDownOpen, setProjectsDropDownOpen] = useState(false)
  
  return (
    <header
      className="navbar navbar-inverse navbar-fixed-top wet-asphalt"
      role="banner"
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className={"navbar-brand"} to="/">
            AJVDC
          </Link>
        </div>
        <div className="collapse navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            
			<li className={setActive("home")}>
              <Link to="/">Home</Link>
            </li>
            
			<li className={setActive("about")}>
              <Link to="/about/">About Us</Link>
            </li>
            
			<li className={setActive("what_we_do")}>
              <Link to="/what_we_do/">What We Do</Link>
            </li>
            
			<li className={`dropdown ${programDropDownOpen ? "open" : ""}`}>
              <a
                href={"#"}
                onClick={event => {
                  event.stopPropagation()
                  setProgramDropDownOpen(!programDropDownOpen)
                }}
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                Programs <i className="icon-angle-down" />
              </a>
              <ul className="dropdown-menu">
                
				<li className={setActive("education")}>
                  <Link to="/education/">
				     Sustainability Education
				  </Link>
                </li>
                
				<li className={setActive("WASH_PCI")}>
                  <Link to="/wash_pci/">
                    Water, Sanitation and Hygene
                  </Link>
                </li>
				
				<li className={setActive("engagement")}>
                  <Link to="/engagement/">
                    Youth Engagement
                  </Link>
                </li>
				
                <li className={setActive("youthAndEnvironment")}>
                  <Link to="/youth_and_environment/">
                    Community Resiliency and the Environment
                  </Link>
                </li>

              </ul>
            </li>
            <li className={setActive("howToHelp")}>
              <Link to="/how_to_help/">How to Help Us</Link>
            </li>
			
			<li className={`dropdown ${projectsDropDownOpen ? "open" : ""}`}>
              <a
                href={"#"}
                onClick={event => {
                  event.stopPropagation()
                  setProjectsDropDownOpen(!projectsDropDownOpen)
                }}
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                Projects 
				<i className="icon-angle-down" />
              </a>
              <ul className="dropdown-menu">
                
				<li className={setActive("successStories")}>
                  <Link to="/success_stories/">
				     Success Stories
				  </Link>
                </li>
                
				<li className={setActive("Projects")}>
                  <Link to="/projects/">
                    Future Projects
                  </Link>
                </li>
				
              </ul>
            </li>
			

            
			
			<li className={setActive("partners")}>
              <Link to="/partners/">Partners and affiliation</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

Navigation.propTypes = {
  pageName: PropTypes.string,
}

Navigation.defaultProps = {
  pageName: ``,
}

export default Navigation
