import PropTypes from "prop-types"
import React from "react"

const Header = ({ headerTitle }) => (
  <section id="title" className="emerald">
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <h1>{headerTitle}</h1>
        </div>
      </div>
    </div>
  </section>
)

Header.propTypes = {
  headerTitle: PropTypes.string,
}

Header.defaultProps = {
  headerTitle: ``,
}

export default Header
